// 黑名单
<template>
  <div class="menu">
    <div class="screening">
      <!-- <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="筛选框" name="1"> -->
          <div class="screening-out">
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item label="被叫号码">
                <el-input
                  v-model="searchFrom.num"
                  placeholder="请输入需要查询的手机号"
                ></el-input>
              </el-form-item>

              <el-form-item label="报备线路">
               <el-select v-model="searchFrom.amountId" placeholder="请选择报备线路" filterable  style="width: 200px;" clearable
                          >
                  <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>  
              </el-form-item>
              <el-form-item label="分配用户">
                <el-select
                  v-model="searchFrom.uid"
                  placeholder="请选择"
                  style="width: 200px;"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in user_list"
                    :key="index"
                    :label="`${item.nickname || ''}--${
                      item.company || ''
                    }--(Id:${item.uid})`"
                    :value="item.uid"
                  >
                    <div class="flex-nowrap-space-between">
                      <div :class="item.status == 1 ? 'no-red' : ''">
                        <span>{{ item.nickname }}--</span>
                        <span>{{ item.company }}--</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>

            

            <!-- <el-form-item> -->
            <div class="title-buttom">
          
              <el-button class="cancel" size="mini" @click="reset" round
                >重置</el-button
              >
              <el-button
                type="primary"
                size="mini"
                class="confirmAdd"
                @click="onSubmit"
                round
                >查询</el-button
              >

              <div  style="margin-left: 10px;">
                <el-dropdown>
              <el-button class="button-el" size="medium">
                <icon-font
                  type="icon-bangdingguanxi"
                  class="button-icon-font"
                />
                批量操作
                <icon-font type="icon-copy-4-36" class="button-icon-font" />
              </el-button>

              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="addData()"
                    >批量增加</el-dropdown-item
                  >
                  <el-dropdown-item @click="recycling(1)"
                    >批量删除</el-dropdown-item
                  >
               
                </el-dropdown-menu>
              </template>
            </el-dropdown>
</div>
             
            </div>
           
            <!-- </el-form-item> -->
          </div>
        <!-- </el-collapse-item>
      </el-collapse> -->
    </div>
    <el-row>
      <el-col :span="12">
        <div class="title-name">数据列表</div>
      </el-col>
      <el-col :span="12">
        <div class="title-buttom">
          <el-tooltip content="刷新列表" placement="top" effect="light">
            <el-button
              class="editor"
              size="mini"
              icon="el-icon-refresh-right"
              @click="reset"
              circle
            ></el-button>
          </el-tooltip>
          <!-- <el-tooltip content="新增菜单" placement="top" effect="light">
                        <el-button class="editor" size="mini" icon="el-icon-plus" @click="addMenu" circle></el-button>
                    </el-tooltip> -->
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div>
          <el-table
            class="eltable"
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
            row-key="id"
            default-expand-all
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @selection-change="handleSelectionChange"
          >
            <!-- <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column> -->
       
            <el-table-column label="分配用户">
              <template #default="scope">{{
                scope.row.nickName == null || scope.row.nickName == ""
                  ? "-"
                  : scope.row.nickName
              }}</template>
            </el-table-column>
            <el-table-column label="被叫号码">
              <template #default="scope">
                <span>{{
                  scope.row.num == null || scope.row.num == ""
                    ? "-"
                    : scope.row.num
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="报备线路">
              <template #default="scope">{{
                scope.row.amountName == null || scope.row.amountName == ""
                  ? "-"
                  : scope.row.amountName
              }}</template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <div class="pager">
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          :page-sizes="[10, 20, 30, 40]"
          :page-size="searchFrom.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

     <!-- 弹窗→操作号码 -->
     <div class="popup-view">
      <el-form label-position="left" label-width="100px" :model="upform" ref="upform" :rules="rules">
        <el-dialog :title="operationType == 'add'?'批量新增':'批量删除'" v-model="centerDialogVisible"
                   :close-on-click-modal="false"
                   :destroy-on-close="true" width="30%">
          <div class="center-body">
            <!-- <el-row :gutter="12">
              <el-col :span="8"> -->
            <div class="form-out-box p-0-30">
              <el-form-item label="被叫号码：" prop="nums">
                <el-input  v-model="upform.nums" :autosize="{ minRows: 3, maxRows: 10 }"
                          type="textarea" placeholder="请输入号码(输入为多个号码时请换行输入)"></el-input>
              </el-form-item>
             
            
          
              <el-form-item label="报备线路：" prop="amountId">
                <el-select v-model="upform.amountId" placeholder="请选择" filterable style="width: 100%" clearable
                           >
                  <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              
           
              
              <el-form-item label="分配用户 "    prop="uid"   >
               <el-select
                  v-model="upform.uid"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in user_list"
                    :key="index"
                    :label="`${item.nickname || ''}--${
                      item.company || ''
                    }--(Id:${item.uid})`"
                    :value="item.uid"
                  >
                    <div class="flex-nowrap-space-between">
                      <div :class="item.status == 1 ? 'no-red' : ''">
                        <span>{{ item.nickname }}--</span>
                        <span>{{ item.company }}--</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
              
            </div>
            <el-form-item>
              <el-button class="cancel" plain @click="centerDialogVisible = false" round>取消</el-button>
              <el-button class="confirmAdd" @click="addSubmit('upform')" round :loading='loding1'>
                保存
              </el-button>
            </el-form-item>
            <!-- </el-col>
            </el-row> -->
          </div>
        </el-dialog>
      </el-form>
    </div>
  </div>
</template>

<script>
import { reportList, agentAdd1 ,deleteBatch,calleeWhite,calleeWhiteAdd,calleeWhiteDle} from "@/api/settings/industry";
import _lineService from "@/api/open/LineQualityInspections";
import amountts from '@/api/open/privacy/amount'
import {message} from 'ant-design-vue'
import userts from "@/api/web/user";
import blackCheck from "@/api/open/black/blackCheck";
import { IconFont } from "@/utils/iconfont";
export default {
  components: {
    IconFont,
  },
  data() {
    return {
       user_list: [], // 用户的集合
       loding1:false,
      rules: {
     
        nums: [
          {
            required: true,
            message: '请填写主叫号码',
            trigger: 'blur',
          },
        ],
        amountId: [
          {
            required: true,
            message: '请选择报备线路',
            trigger: 'blur',
          },
        ],
        uid: [
          {
            required: true,
            message: '请选择分配用户',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: '请输入服务商名称',
            trigger: 'blur',
          },
        ],
      },
      centerDialogVisible:false,
      upform:{
        nums:'',
        amountId:'',
        name:'',
        company:'',
         uid:''
      },
      tableHeight: "",
      searchFrom: {
        page: 1,
        pageSize: 10,
        amountId: "", //识别类型
        num: "",
        uid:''
      },
      amountOption: [],
      total: 0,
      currentPage: 1, //分页
      multipleSelection: [], //选中集合
      operationType: "", //操作类型
      activeNames: [], //折叠面板
      tableData: [], //菜单表
      // loding 状态启用
      loading: true,
      amount_id:[]
    };
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight;
    this.getAmountList();
    this.getUserList()
  },
  watch: {},
  mounted() {
    this.getDataList(true);
  },
  methods: {
     // 获取用户列表
    getUserList() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data;
      });
    },
    unique(arr) {
      const res = new Map()
      return arr.filter((a) => !res.has(a) && res.set(a, 1))
    },


  // 号码添加/修改--提交
  addSubmit( formName) {
      
      this.$refs[formName].validate((valid) => {
        if (valid) {
         
            let arrLest = []
            let upArr = []
            arrLest = this.upform.nums.split(/[(\r\n)\r\n]+/)
            this.unique(arrLest).forEach((item, i) => {
              upArr.push(
                item.trim(),
              
              
              )
            })
              this.loding1=true
            if(this.operationType=='add'){
                 console.log('111111111111111111');
              calleeWhiteAdd({ 
                nums: upArr,
                uid:this.upform.uid,
                amountId: this.upform.amountId
                } )
                .then((res) => {
                  this.centerDialogVisible = false
                   this.loding1=false
                  if (res.code === 200) {
                    message.success('添加成功')
                    // 获取最新数据
                    this.getDataList(true);

                    this.handleSizeChange(this.searchFrom.pageSize)
                    this.upform = {}
                  } else {
                    message.error(res.message)
                    this.loding1=false
                  }
                })
           return

            }
            if(this.operationType=='del'){
              calleeWhiteDle({ nums: upArr,
        
                  uid:this.upform.uid,
                amountId: this.upform.amountId})
                .then((res) => {
                  this.centerDialogVisible = false
                  this.loding1=false
                  if (res.code === 200) {
                    message.success('删除成功')
                    // 获取最新数据
                    this.getDataList(true);

                    this.handleSizeChange(this.searchFrom.pageSize)
                    this.upform = {}
                  } else {
                    message.error(res.message)
                    this.loding1=false
                  }
                })
           return

            }
            // this.upform.number = this.unique(otherArr)
            



        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    addData() {
      this.operationType='add'
      this.upform = {}

      this.centerDialogVisible = true

    },
    recycling() {
      this.operationType='del'
      this.upform = {}

      this.centerDialogVisible = true

    },
    getAmountType() {
      amountts.getAmountList({}).then((res) => {
        this.amount_id = res.data
      })
    },
    async getAmountList() {

      const res = await _lineService._recordService.getAmountList();
      this.amount_id = res.data

      this.amountOption =
        res?.data?.map((item) => {
          return { label: item.name, value: item.id };
        }) || [];
        console.log(this.amountOption);
       // this.getAmountType()
    },
    // 查询
    onSubmit() {
      this.getDataList(true);
    },
    // 重置
    reset() {
      (this.searchFrom = {
        page: this.currentPage,
        pageSize: 10,
        uid: "",
        amountId:"",
        num: "",
      }),
        this.getDataList(true);
    },
    // 获取数据列表
    getDataList(type) {
      this.loading = type;
      calleeWhite({
        ...this.searchFrom,
      }).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.searchFrom.pageSize = val;
      this.getDataList(true);
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.searchFrom.page = val;
      this.currentPage = val;
      this.getDataList(true);
    },
    // 折叠面板
    handleChange(val) {
      console.log(val);
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量删除
    deleteData() {
      console.log(this.multipleSelection);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  height: 100%;
  overflow-y: overlay;
  padding-right: 18px;
  .title-name {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }
  .top-first {
    .search-box {
      width: 18.75rem;
    }
  }
}
.screening {
  margin-bottom: 0.9375rem;
}
.el-row {
  margin-bottom: 0.9375rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.editor {
  color: #0170ed;
  background: #e3f0ff;
}
.center-body {
  margin: 0 1.875rem;
}
.deletetable {
  color: #ff845b;
  background: #ffe4db;
}
.deletetable-other {
  margin-right: 1.25rem;
}
.confirmAdd {
  background: #637dff;
  color: #fff;
}
.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}
.screening-out {
  margin: 0 0.9375rem;
}
.out-icon:hover {
  background: #e3f0ff;
  color: #0170ed;
}
.pager {
  display: flex;
  justify-content: flex-end;
}
.pass {
  color: #67c23a;
}
.unpass {
  color: #f56c6c;
}
.priceColor {
  color: #409eff;
}
</style>

<style lang="scss">
.menu .el-collapse-item__content {
  padding-bottom: 0 !important;
}
.eltable {
  overflow-y: overlay;
}
</style>
